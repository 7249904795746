import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import * as dataquery from "../dataService/DataService";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

export default function DeleteCollateralsModal({
  row,
  show,
  handleClose,
  rowData,
  rowNameData,
  setDeleteLoading,
  deleteLoading,
}) {
  const queryClient = useQueryClient();

  const handleArchive = (data) => {
    deleteAwardRecord.mutate(data);
};
const deleteAwardRecord = useMutation(dataquery.deleteCollaterals, {
    onError: (error) => {
        toast.dismiss();
        toast.error(error.message);
        setDeleteLoading(false)
    },
    onSuccess: (data) => {
        toast.dismiss();
        toast.success(data.message);
        queryClient.invalidateQueries("getAllCollaterals");
        setDeleteLoading(false)
        handleClose();
    },
    onMutate:()=>{
        setDeleteLoading(true)
    }
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <p>
              Once you delete this data, you won't be able to recover it. Are
              you sure you want to go ahead?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={deleteLoading}
            onClick={() => handleArchive(rowData)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
