import { React, useState, useEffect, useRef } from "react";
import backarrow from "../../assets/images/back-arrow.svg";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import pdfImg from "../../assets/images/pdf-doc.svg";
import { faPlus, faTrash, faTrashAlt, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";

import * as querydata from "../dataService/DataService";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getRestaurantType,
  getRestaurantTags,
  insertRestaurantData,
  previewRestaurant,
} from "../dataService/DataService";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewForDinning from "../manageAlbum/AlbumViewForDinning";

import MultipleRestaurantTag from "./MultiRestaurantTag";
import RestaurantDetail from "./RestaurantDetail";
import RestaurantTag2 from "./RestaurantTag2";
import { toast } from "react-toastify";
import { useContext } from "react";
import RestaurentTags from "./RestaurentTags";

import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
// import MultipleImageAltTag from "./MultipleImageAltTags";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { GlobalContext } from "../../globalContext";
import ResortTypeModal from "./ResortTypeModal";
import RestaurantSelect from "./RestaurantSelect";
import DeleteRestaurantTypeModal from "./DeleteRestaurantTypeModal";
function AddNewRestaurant() {
  const { bwebsiteId } = useContext(GlobalContext);
  const moduleName = "dinning";
  // Get the mandatory fields for the specific websiteId and moduleName
  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);
  const RestaurantSchema = () =>
    Yup.object({
      RestaurantName: Yup.string()
        .required("Please enter restaurant name")
        .trim(),
      highlightsTitle: Yup.string()
        .trim()
        .test(
          "is-mandatory-highlightsTitle",
          "Please enter highlights title",
          function (value) {
            return (
              !extraFields.includes("highlightsTitle") ||
              (value && value.trim() !== "")
            );
          }
        ),
      highlightsDescription: Yup.string()
        .trim()
        .test(
          "is-mandatory-highlightsDescription",
          "Please enter highlights description",
          function (value) {
            return (
              !extraFields.includes("highlightsDescription") ||
              (value && value.trim() !== "")
            );
          }
        ),
      RestaurantTagLine: Yup.string()
        .required("Please enter restaurant tagline")
        .trim(),

      RestaurantDescription: Yup.string()
        .required("Please enter restaurant description")
        .trim(),

      slugName: Yup.string()
        .matches(
          /^[a-zA-Z0-9_-]*$/,
          "Only alphanumeric characters and underscores are allowed"
        )
        .required("Please enter slug"),

      metaTitle: Yup.string().trim(),
      keyWords: Yup.string().trim(),
      metaDescription: Yup.string().trim(),

      dataArray: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("This field requires a heading").trim(),

          tagDescription: Yup.string()
            .trim()
            .test(
              "is-mandatory-tagDescription",
              "This field requires a description",
              function (value) {
                return (
                  !mandatoryFields.includes("tagDescription") ||
                  (value && value.trim() !== "")
                );
              }
            ),

          tagFeatures: Yup.array()
            .of(
              Yup.string()
                .required("Please add at least one tag feature")
                .trim()
            )
            .test(
              "is-mandatory-tagFeatures",
              "At least one tag feature is required",
              function (value) {
                return (
                  !mandatoryFields.includes("tagFeatures") ||
                  (value && value.length > 0)
                );
              }
            ),
        })
      ),
    });

  //  const RestaurantSchema = Yup.object({
  //   RestaurantName: Yup.string().required("Please enter restaurant name").trim(),
  //   // highlightsTitle: Yup.string()//condition
  //   //   .required("Please enter highlights title ")
  //   //   .trim(),
  //     highlightsTitle: Yup.string().trim().test(
  //       'is-highlightsTitle-required',
  //       "Please enter highlights title",
  //       function (value) {
  //           return !extraFields.includes('highlightsTitle') || !!value; // If discountText is required, ensure it's not empty
  //       }
  //   ),
  //   // highlightsDescription: Yup.string()//condition
  //   // .required("Please enter highlights description ")
  //   //   .trim(),
  //   highlightsDescription: Yup.string().trim().test(
  //     'is-highlightsDescription-required',
  //     "Please enter highlights description",
  //     function (value) {
  //         return !extraFields.includes('highlightsDescription') || !!value; // If discountText is required, ensure it's not empty
  //     }
  // ),
  //   RestaurantTagLine: Yup.string()
  //     .required("Please enter restaurant Tagline")
  //     .trim(),
  //   RestaurantDescription: Yup.string()
  //     .required("Please enter restaurant description")
  //     .trim(),
  //   slugName: Yup.string()
  //     .matches(
  //       /^[a-zA-Z0-9_-]*$/,
  //       "Only alphanumeric characters, hyphens, and underscores are allowed"
  //     )
  //     .required("Please enter slug"),
  //   metaTitle: Yup.string().trim(),

  //   keyWords: Yup.string().trim(),
  //   metaDescription: Yup.string().trim(),

  //   // RestaurantTags:Yup.string().required("Please enter restaurant name").trim(),
  //   // RestaurantTypeId: Yup.number().required("Please select restaurant type").typeError('Please select restaurant type'),

  //   dataArray: Yup.array().of(
  //     Yup.object().shape({
  //       name: Yup.string().required("This field requires a heading").trim(),
  //       // tagDescription: Yup.string()
  //       //   // .required("This field requires a description")//condition
  //       //   .trim(),
  //       tagDescription: Yup.string().trim().test(
  //         'is-tagDescription-required',
  //         "This field requires a description",
  //         function (value) {
  //             return !mandatoryFields.includes('tagDescription') || !!value; // If discountText is required, ensure it's not empty
  //         },
  //       ),
  //       //  tagFeatures: Yup.array()//condition
  //       //    .of(Yup.string().required("Please add atleast one tag Feature").trim())
  //       //    .min(1, "At least one tag feature is required"),
  //       tagFeatures: Yup.string().trim().test(
  //         'is-tagFeatures-required',
  //         "Please add atleast one tag Feature",
  //         function (value) {
  //             return !extraFields.includes('tagFeatures') || !!value; // If discountText is required, ensure it's not empty
  //         },
  //       ),
  //     })
  //   ),
  // });
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState({});
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let AllRestaurantTypeId = useQuery("getAllRestaurantTypes", getRestaurantType);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [maincroppedSecImage, setmainCroppedSecImage] = useState(null);
  const [maincroppedThirdImage, setmainCroppedThirdImage] = useState(null);
  const [maincroppedSecFile, setmainCroppedSecFile] = useState(null);
  const [maincroppedThirdFile, setmainCroppedThirdFile] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkValForSingleImg3, setValForSingleImg3] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [checkValForIcon, setValForIcon] = useState("");
  const [mainCroppedIcon, setMainCroppedIcon] = useState(null);
  const [icon, setIcon] = useState(null);
  const [mainCroppedIconFile, setmainCroppedIconFile] = useState(null);
  const [currentFileId, setCurrentFileId] = useState("");
  const [BannerImage, setBannerImage] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] =
    useState(null);
  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [settingValue, setSettingValue] = useState("");

  const [imgArray, setImgArray] = useState(null); //use to get the index of the selected detail array Image
  const closeCropMediaModal = () => setCropMediaModal(false);

  // menu work start//condition if needed start
  const [restMenu, setrestMenu] = useState([]);
  const [restMenuValue, setrestMenuValue] = useState();
  const [indexForMenu, setIndexForMenu] = useState();

  //restaurant logo

  const [restLogo, setrestLogo] = useState();
  const [restLogoValue, setrestLogoValue] = useState();

  //for left image
  const [leftImage, setLeftImage] = useState();
  const [leftImageValue, setLeftImageValue] = useState();

  //for righrt  image
  const [rightImage, setRightImage] = useState();
  const [rightImageValue, setRightImageValue] = useState();

  //for chief signature image

  const [chefSignatue, setChefSignatue] = useState();
  const [chefSignatueValue, setChefSignatueValue] = useState();
  const getLeftRightsettingvalue = useQuery(
    ["AspectRatio", "dining_left_image_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_left_image_aspect_ratio")
  );
  const getChefSignatureAspectRatio = useQuery(
    ["AspectRatio", "dining_chef_signature_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_chef_signature_aspect_ratio")
  );
  const getRestaurantLogoAspectRatio = useQuery(
    ["AspectRatio", "dining_restaurant_logo_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_restaurant_logo_aspect_ratio")
  );
  //condition if needed end

  const showCropMediaModal = (id, val, index) => {
    setCurrentFileId(id);
    if (val === "firstImage") {
      setValForSingleImg(val);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getCardImageAspectRatio?.data?.data?.setting_value);
    }
    if (val === "secondImage") {
      setMultipleImageSection(val);
      setValForSingleImg("");
      setValForIcon(null);
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    if (val === "thirdImage") {
      setImgArray(index);
      setValForSingleImg3(val);
      setMultipleImageSection(null);
      setValForSingleImg(null);
      setValForBannerImage(null);
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }

    if (val === "BannerImage") {
      setValForBannerImage(val);
      setValForSingleImg(null);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setSettingValue(
        getInnerImageAspectRatio?.data?.data?.setting_value
          ? getInnerImageAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "restaurantIcon") {
      setValForIcon(val);
      setValForSingleImg(null);
      setValForSingleImg3(null);
      setMultipleImageSection(null);
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "retsmenu") {
      setIndexForMenu(index);
      setrestMenuValue(val);
    }
    if (val === "restLogo") {
      setrestLogoValue(val);
      setSettingValue(
        getRestaurantLogoAspectRatio?.data?.data?.setting_value
          ? getRestaurantLogoAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "leftImage") {
      setLeftImageValue(val);
      setSettingValue(
        getLeftRightsettingvalue?.data?.data?.setting_value
          ? getLeftRightsettingvalue?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "rightImage") {
      setSettingValue(
        getLeftRightsettingvalue?.data?.data?.setting_value
          ? getLeftRightsettingvalue?.data?.data?.setting_value
          : 1 / 1
      );
      setRightImageValue(val);
    }
    if (val === "chefSignature") {
      setChefSignatueValue(val);
      setSettingValue(
        getChefSignatureAspectRatio?.data?.data?.setting_value
          ? getChefSignatureAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    setCropMediaModal(true);
  };
  const queryClient = useQueryClient();

  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "dining_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_image_dimension")
  );

  const getInnerImageAspectRatio = useQuery(
    ["AspectRatio", "dining_inner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_inner_image_dimension")
  );

  const getCardImageAspectRatio = useQuery(
    ["AspectRatio", "dining_card_image_dimension"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_card_image_dimension")
  );

  const handleRemoveImage = (e, value) => {
    e.preventDefault();
    if (value === "RestaurantImage") {
      setmainCroppedFile(null);
      if (e) {
        setImage(null);
        setmainCroppedImage(null);
      }
    }
    if (value === "RestaurantLogo") {
      setrestLogo(null);
    }

    //  setmainCroppedBannerImageFile(null);//condition
    //     if (e) {//condition
    //       setBannerImage(null);
    //       setMainCroppedBannerImage(null);
    //     }
    if (value === "leftImage") {
      setLeftImage(null);
      // setSettingValue(getLeftRightsettingvalue?.data?.data?.setting_value)
    }
    if (value === "rightImage") {
      setRightImage(null);
      // setSettingValue(getLeftRightsettingvalue?.data?.data?.setting_value)
      // setSettingValue(getLeftRightsettingvalue?.data?.data?.setting_value ? getLeftRightsettingvalue?.data?.data?.setting_value : 1 / 1);
    }
    if (value === "chefSignature") {
      setChefSignatue(null);
    }

    // Handle removing the image
  };
  const handleBannerImage = (e) => {
    setmainCroppedBannerImageFile(null);
    if (e) {
      setBannerImage(null);
      setMainCroppedBannerImage(null);
    }
  };
  // const handleRemoveIcon = (e) => {
  //   // Handle removing the image
  //   setmainCroppedIconFile(null)
  //   if (e) {
  //     setIcon(null);
  //     setMainCroppedIcon(null);
  //   }
  // };
  const handleSecRemoveImage = (e) => {
    // Handle removing the image
    setmainCroppedSecFile(null);
    if (e) {
      setImage2(null);
      setmainCroppedSecImage(null);
    }
  };
  const handleThirdRemoveImage = (e, sectionId, index) => {
    if (maincroppedThirdFile === null) {
      return;
    }
    const update = Object.values(maincroppedThirdFile);

    update[index] = null;
    setmainCroppedThirdFile(update);
    setImage3((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });
  };
  const [featureDropdowns, setFeatureDropdowns] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const handleAddRestaurantTag = (index) => {
    const newDropdownKey = index.toString();
    setFeatureDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: "",

        onDelete: () => handleRemoveFeatureDropdown(newDropdownKey),
      },
    ]);
  };

  const handleRemoveFeatureDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `.row.position-relative[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }

    const data = values.Menus;
    const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

    // Check if the key exists before removing
    if (data.hasOwnProperty(keyToRemove)) {
      delete data[keyToRemove];

      // updateDisplay();
    } else {
    }
  };
  // const handleChangeRestaurantTag = (event, dropdownKey) => {
  //   setValues((prevValues) => {
  //     return {
  //       ...prevValues,
  //       RestaurantTags: {
  //         ...prevValues.RestaurantTags,
  //         [dropdownKey]: event.target.value,
  //       },
  //     };
  //   });
  // };

  // crop code end
  const [restaurandDetailDataArray, setRestaurandDetailDataArray] = useState([
    {
      id: 1,
      name: "Cuisine",
      tagImage: "",
      tagHeading: "",
      tagDescription: "",
      tagFeatures: [], //condition
      detailImgAltTag: "",
    },
    {
      id: 2,
      name: "Ambience",
      tagImage: "",
      tagHeading: "",
      tagDescription: "",
      tagFeatures: [], //condition
      detailImgAltTag: "",
    },
  ]);

  const [initialValues, setInitialValues] = useState({
    RestaurantName: "",
    slugName: "",
    metaTitle: "",
    keyWords: "",
    metaDescription: "",
    RestaurantTypeId: "",
    RestaurantTagLine: "",
    Menus: "",
    RestaurantTags: "",
    highlightsTitle: "",
    highlightsDescription: "",
    RestaurantDescription: "",
    imageAltTag: "",
    innerBannerImgTag: "",

    leftImageTag: "",
    rightImageTag: "",
    chefSignatureImageTag: "",
    restayrantLogoImageTag: "",
    // detailImgAltTag:[],
    dataArray: restaurandDetailDataArray,
  });

  const [dataArray, setDataArray] = useState(initialValues.dataArray);

  useEffect(() => {
    setDataArray((prevDataArray) => {
      return prevDataArray.map((data) => ({
        ...data,
        tagImage:
          maincroppedThirdFile && maincroppedThirdFile[`section_${data.id}`]
            ? maincroppedThirdFile[`section_${data.id}`]
            : null,
      }));
    });
  }, [maincroppedThirdFile]);

  const RestaurantNameRef = useRef(null);
  const RestaurantTagLineRef = useRef(null);
  const RestaurantDescriptionRef = useRef(null);
  const ImageRef = useRef(null);
  const highlightsDescriptionRef = useRef(null);
  const highlightsTitleRef = useRef(null);
  const BannerImageRef = useRef(null);
  const slugRef = useRef(null);
  const handleRestaurantNameChange = (event) => {
    const value = event.target.value;

    setFieldValue("RestaurantName", value);
    setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const [typeForImgAltTag, setTypeForImgAltTag] = useState("");
  const [altTags, setAltTags] = useState({
    IMAGEALTTAG: "",
    chefSignatureImageTag: "",
    leftImageTag: "",
    restayrantLogoImageTag: "",
    rightImageTag: "",
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RestaurantSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("restaurantTypeId", values.RestaurantTypeId);
      formData.append("restaurantDescription", values.RestaurantDescription);
      formData.append("restaurantName", values.RestaurantName);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("restaurantTagline", values.RestaurantTagLine);
      formData.append("imageAltTag", altTags.IMAGEALTTAG);
      formData.append("innerBannerImgTag", values.innerBannerImgTag);
      formData.append("highlightsTitle", values.highlightsTitle);
      formData.append("highlightsDescription", values.highlightsDescription);

      if (values.dataArray) {
        values.dataArray.forEach((data, index) => {
          if (extraFields.includes("tagFeatures")) {
            const detailFeatures = Object.values(data.tagFeatures); //condition

            const elementData = {
              heading: data.name,
              description: data.tagDescription,
              features: detailFeatures, //condition
              detailImgAltTag: data.detailImgAltTag,
            };

            formData.append(
              `restaurantDetail[${index}]`,
              JSON.stringify(elementData)
            );
          } else {
            const elementData = {
              heading: data.name,
              description: data.tagDescription,
              detailImgAltTag: data.detailImgAltTag,
            };

            formData.append(
              `restaurantDetail[${index}]`,
              JSON.stringify(elementData)
            );
          }
        });
      }

      if (values.RestaurantTags) {
        const restaurantArray = Object.values(values.RestaurantTags);

        restaurantArray.map((restTags, index) => {
          formData.append(`restaurantTags[${index}]`, restTags);
        });
      }

      if (values.Menus) {
        const menuArray = Object.values(values.Menus);
        menuArray.map((menuTags, index) => {
          formData.append(`menuTags[${index}]`, menuTags);
        });
        // restMenu?.map((item, index) => formData.append(`menu`, item));
        for (let menuItem of restMenu) {
          if (menuItem === "") {
            toast.dismiss();
            toast.error("please upload menu item ");
            return;
          }
          formData.append(`menu`, menuItem);
        }

        for (let i = 0; i < menuArray?.length; i++) {
          if (restMenu.length > 0) {
            if (menuArray[i] === "") {
              toast.dismiss();
              toast.error("please upload menu title ");
              return;
            }
          }
        }

        // formData.append("Menu",restMenu)
      }

      if (maincroppedFile === null) {
        toast.dismiss();
        if (ImageRef.current) {
          ImageRef.current.focus();
        }
        toast.error("Please upload banner image");
        return;
      }
      if (extraFields.includes("innerBannerImage")) {
        if (mainCroppedBannerImageFile === null) {
          //condition
          if (BannerImageRef.current) {
            BannerImageRef.current.focus();
          }
          toast.dismiss();
          toast.error("Please upload inner banner image");
          return;
        }
      }
      // if (maincroppedSecFile === null) {

      //   toast.error("Please upload menu activities ")
      //   return
      // }
      console.log("values.dataArray", values.dataArray);
      if (values.dataArray.length !== 0) {
        if (maincroppedThirdFile === null) {
          toast.dismiss();
          toast.error("Please upload tag image ");
          return;
        } else {
          const detailImagesArrayFromObject =
            Object.values(maincroppedThirdFile);

          for (let i = 0; i < detailImagesArrayFromObject.length; i++) {
            if (detailImagesArrayFromObject[i] === null) {
              toast.dismiss();
              toast.error("Please upload tag image ");
              return;
            }
          }

          if (values.dataArray.length !== detailImagesArrayFromObject.length) {
            toast.dismiss();
            toast.error("Please upload all tag image ");
            return;
          }
        }
      }

      // formData.append("menuImage", maincroppedSecFile);//condition

      formData.append("restaurantImage", maincroppedFile);
      if (extraFields.includes("innerBannerImage")) {
        if (mainCroppedBannerImageFile) {
          //condition
          formData.append("innerBannerImage", mainCroppedBannerImageFile);
        }
      }

      // formData.append("restaurantIcon", mainCroppedIconFile);
      if (values.dataArray.length !== 0) {
        if (maincroppedThirdFile) {
          const detailImagesArrayFromObject =
            Object.values(maincroppedThirdFile);

          detailImagesArrayFromObject.map((detailImage, index) => {
            if (detailImage !== null && detailImage !== undefined) {
              formData.append(`detailImages`, detailImage);
              // const altTag = initialValues.detailImgAltTag[index];
              // const altTagValue = altTag !== undefined ? altTag : ""; // If altTag is undefined, use ""
              // formData.append(`detailImgAltTag[${index}]`, altTagValue);
              // formData.append(`detailImgAltTag[${index}]`, altTags);
            }
          });
        }
      }
      if (extraFields.includes("leftImage")) {
        if (leftImage) {
          formData.append("leftImage", leftImage);
          formData.append("leftImageAltTag", altTags.leftImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload left image ");
          return;
        }
      }
      if (extraFields.includes("rightImage")) {
        if (rightImage) {
          formData.append("rightImage", rightImage);
          formData.append("rightImageAltTag", altTags.rightImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload right image ");
          return;
        }
      }

      if (restLogo) {
        formData.append("restaurantIcon", restLogo);
        formData.append("restaurantIconAltTag", altTags.restayrantLogoImageTag);
      }
      if (extraFields.includes("chefSignatue")) {
        if (chefSignatue) {
          formData.append("chefSignature", chefSignatue);
          formData.append("chefSignatureAltTag", altTags.chefSignatureImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload chef signature  ");
          return;
        }
      }

      if (actions === "PREVIEW") {
        preview.mutate(formData);
      }
      if (actions === "SAVE") {
        addnewprojectMutation.mutate(formData);
      }
    },
    // validateOnBlur: false,
    // validateOnChange: false,
  });

  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      dataArray: restaurandDetailDataArray,
    }));

    // Reset the form with new initial values
    formik.resetForm({
      values: {
        ...formik.values,
        dataArray: restaurandDetailDataArray,
      },
    });
  }, [restaurandDetailDataArray, formik.resetForm]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
    validateOnBlur,
    validateOnChange,
  } = formik;

  // useEffect(() => {
  //   if (Object.values(errors).some((error) => error !== undefined)) {
  //     const firstEmptyField = Object.entries(errors).find(
  //       ([, error]) => error !== undefined && !touched[error.path]
  //     );

  //     if (firstEmptyField) {
  //       const [name] = firstEmptyField;
  //       switch (name) {
  //         case "RestaurantName":
  //           RestaurantNameRef.current.focus();
  //           break;
  //         case "RestaurantTagLine":
  //           RestaurantTagLineRef.current.focus();
  //           break;
  //         case "RestaurantDescription":
  //           RestaurantDescriptionRef.current.focus();
  //           break;
  //         case "slugName":
  //           slugRef.current.focus();
  //           break;
  //         case "highlightsTitle":
  //           highlightsTitleRef.current.focus();
  //         case "highlightsDescription":
  //           highlightsDescriptionRef.current.focus();
  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }, [errors]);
  const handelFocus=()=>{
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;
        switch (name) {
          case "RestaurantName":
            RestaurantNameRef.current.focus();
            break;
          case "RestaurantTagLine":
            RestaurantTagLineRef.current.focus();
            break;
          case "RestaurantDescription":
            RestaurantDescriptionRef.current.focus();
            break;
          case "slugName":
            slugRef.current.focus();
            break;
          case "highlightsTitle":
            highlightsTitleRef.current.focus();
          case "highlightsDescription":
            highlightsDescriptionRef.current.focus();
          default:
            break;
        }
      }
    }
    handleSubmit()
  }

  const addnewprojectMutation = useMutation(insertRestaurantData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      queryClient.resetQueries("getAllRestaurents");

      navigate("/manage-dining");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  const preview = useMutation(previewRestaurant, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      window.open(data?.websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });

  const checkErrorAndSubmit = () => {
    if (
      Object.keys(errors).length > 0 &&
      !errors?.RestaurantName &&
      !errors?.RestaurantDescription &&
      !errors.RestaurantTagLine
    ) {
      toast.dismiss();
      toast.error("Please fill all the fields ");
      handelFocus();
    } else {
      handelFocus();
    }
  };

  const [showOfferImgTagModal, setShowOfferImgTagModal] = useState(false);
  const [showInnerBannerModal, setShowInnerBannerModal] = useState(false);

  const handleToggleOfferImgModal = (type) => {
    setTypeForImgAltTag(type);
    setShowOfferImgTagModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    setAltTags((prevAltTags) => ({
      ...prevAltTags,
      [tagType]: value,
    }));
    setShowOfferImgTagModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };
  const handleCloseOfferImgTagModal = () => {
    setShowOfferImgTagModal(false);
  };

  // Function to close Inner Banner modal
  const handleCloseInnerBannerModal = () => {
    setShowInnerBannerModal(false);
  };

  const handleToggleInnerBannerModal = () => {
    setShowInnerBannerModal((prevState) => !prevState);
  };

  // const handleToggleOfferImgModal = () => {
  //   setShowOfferImgTagModal((prevState) => !prevState);
  // };
  // const handleSaveImageAltTag = (tagType, value) => {
  //   // Save the image alt tag value to state

  //   setFieldValue("imageAltTag", value);
  //   setShowOfferImgTagModal(false);
  //   // You can also perform any additional actions here, such as making API calls to save the data to a server
  // };

  const handleSaveInnerBannerTag = (tagType, value) => {
    // Save the inner banner tag value to state
    setFieldValue("innerBannerImgTag", value);
    setShowInnerBannerModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  const [showResortModal, setShowResortModal] = useState(false);
  const handleShowResortModal = () => {
    setShowResortModal(true);
  };
  const handleCloseResortTypeModal = () => {
    setShowResortModal(false);
    setEditrestId(null)
  };
  const [editRestaurantId, setEditrestId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setEditrestId(null)
  };
  const handleEdit = (restaurantTypeId) => {
    console.log("restaurantTypeId", restaurantTypeId)
    if (restaurantTypeId) {
      setEditrestId(restaurantTypeId)
      setShowResortModal(true)
    }

  };


  const handleInvalidateRestaurantTypes = () => {
    // Invalidate the query to refetch restaurant types
    setEditrestId(null)
    queryClient.invalidateQueries('getAllRestaurantTypes');
  };
  const handleDelete = (rowData) => {
    console.log("row", rowData)
    setSelectedRowData(rowData.value);
    setSelectedRowNameData(rowData.label);
    setShowDeleteModal(true);
    // Logic for handling the delete, e.g., show a confirmation and delete the entry
    // if (window.confirm("Are you sure you want to delete this restaurant type?")) {
    //   console.log("Delete Restaurant Type ID:", restaurantTypeId);
    //   // Implement your delete logic here, such as sending a delete request to the server
    // }
  };


  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-dining">
                    Manage Dining
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Restaurant
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-item-center">
              <Link
                to="/manage-dining"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Create Restaurant</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Create Restaurant</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="d-flex align-items-center">
                              <Form.Label className="mb-0">
                                Image <span className="mandatory">*</span>{" "}
                              </Form.Label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleOfferImgModal("IMAGEALTTAG")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!maincroppedImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "firstImage")
                                  }
                                  ref={ImageRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, "RestaurantImage")
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        {extraFields.includes("innerBannerImage") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Inner Banner Image{" "}
                                  <span className="mandatory">*</span>
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={(e) =>
                                    handleToggleInnerBannerModal(
                                      "INNERBANNERIMGTAG"
                                    )
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!maincroppedBannerImage && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    for="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "BannerImage")
                                    }
                                    ref={BannerImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {maincroppedBannerImage && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={maincroppedBannerImage}
                                      target="_blank"
                                    >
                                      <img
                                        src={maincroppedBannerImage}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) => handleBannerImage(e)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                            </Form.Group>
                          </Col>
                        )}
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Name{" "}
                              <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              ref={RestaurantNameRef}
                              type="text"
                              className="py-3 shadow-none px-4"
                              value={values.RestaurantName}
                              onChange={handleRestaurantNameChange}
                              name="RestaurantName"
                              onBlur={handleBlur}
                              maxLength={50}
                            />
                            {errors.RestaurantName && touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantName}
                              </p>
                            ) : error.RestaurantName ? (
                              <p className="form-error text-danger">
                                {error.RestaurantName}
                              </p>
                            ) : !errors.description &&
                              error.RestaurantName &&
                              touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                Please enter offer restaurant name
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {" "}
                              Restaurant Type{" "}

                            </Form.Label>
                          
                            <RestaurantSelect
                              AllRestaurantTypeId={AllRestaurantTypeId}
                              values={values}
                              //  handleBlur={handleBlur}
                              handleEdit={handleEdit} // Pass the edit function
                              handleDelete={handleDelete} // Pass the delete function
                              handleChange={formik.handleChange} // Pass Formik handleChange
                              handleBlur={formik.handleBlur} // Pass Formik handleBlur
                              setFieldValue={formik.setFieldValue}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>

                          <Form.Group
                            // className="mb-3"
                            controlId="exampleForm.ControlInput1"
                            className="py-3 shadow-none px-4"
                          >
                            <div className="mt-4">
                              <Button variant="primary" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" onClick={handleShowResortModal}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                              </Button>
                            </div>

                          </Form.Group>
                        </Col>
                        {/* <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {" "}
                              Restaurant Type{" "}
                           
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="py-3 shadow-none px-4"
                              onChange={handleChange}
                              value={values.RestaurantTypeId}
                              name="RestaurantTypeId"
                              onBlur={handleBlur}
                            >
                              <option value={""}>---Select---</option>
                              {AllRestaurantTypeId.data &&
                                AllRestaurantTypeId.data.data.map((option) => (
                                  <option
                                    key={option.restaurant_type_id}
                                    value={option.restaurant_type_id}
                                  >
                                    {option.restaurant_type}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.RestaurantTypeId &&
                            touched.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTypeId}
                              </p>
                            ) : errors.RestaurantTypeId &&
                              touched.RestaurantTypeId ? (
                              <p className="form-error text-danger"></p>
                            ) : null}
                          </Form.Group>
                        </Col> */}
                        {extraFields.includes("restLogo") && (
                          <Col lg={12}>
                            <Form.Group className="mb-3" controlId="Rest Logo">
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Restaurant Logo <span className=""></span>{" "}
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={() =>
                                    handleToggleOfferImgModal(
                                      "restayrantLogoImageTag"
                                    )
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!restLogo && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "restLogo")
                                    }
                                    // ref={ImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {restLogo && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={URL.createObjectURL(restLogo)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(restLogo)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, "RestaurantLogo")
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="TagLine ">
                            <Form.Label>
                              Tag Line <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantTagLine}
                              ref={RestaurantTagLineRef}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantTagLine",
                                  e.target.value
                                )
                              }
                              name="RestaurantTagLine"
                              // maxLength={30}
                            />
                            {errors.RestaurantTagLine &&
                            touched.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTagLine}
                              </p>
                            ) : error.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTagLine}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Description">
                            <Form.Label>
                              Description <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              onChange={handleChange}
                              ref={RestaurantDescriptionRef}
                              value={values.RestaurantDescription}
                              name="RestaurantDescription"
                              onBlur={handleBlur}
                              // maxLength={30}
                            />
                            {errors.RestaurantDescription &&
                            touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantDescription}
                              </p>
                            ) : error.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {error.RestaurantDescription}
                              </p>
                            ) : !errors.RestaurantDescription &&
                              error.RestaurantDescription &&
                              touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                Please select a restaurant description
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Rest Tag">
                            <Form.Label>Restaurant Tags</Form.Label>

                            <RestaurantTag2
                              setFieldValue={setFieldValue}
                              name={getFieldProps("RestaurantTags").name}
                              value={getFieldProps("RestaurantTags").value}
                              maxLength={20}
                              initialValues={initialValues}
                              values={values}
                              formik={formik}
                            />
                          </Form.Group>
                        </Col>

                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Icon
                            </Form.Label>
                            {!mainCroppedIcon && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "restaurantIcon")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {mainCroppedIconFile && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={mainCroppedIcon} target="_blank">
                                    <img
                                      src={mainCroppedIcon}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleRemoveIcon(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}
                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Show Menu/Activities</Form.Label>
                            {!maincroppedSecImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "secondImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose menu from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedSecImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={
                                    maincroppedSecImage.endsWith(".pdf")
                                      ? {
                                          // width: "68%",
                                          height: "68%",
                                        }
                                      : { width: "100%", height: "100%" }
                                  }
                                >
                                  <a
                                    href={
                                      maincroppedSecImage.endsWith(".pdf")
                                        ? pdfImg
                                        : maincroppedSecImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        maincroppedSecImage.endsWith(".pdf")
                                          ? pdfImg
                                          : maincroppedSecImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        // objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleSecRemoveImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}

                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Restaurant Tags</Form.Label>

                            <RestaurentTags
                              showCropMediaModal={showCropMediaModal}
                              setFieldValue={setFieldValue}
                              name={getFieldProps("RestaurantTags").name}
                              value={getFieldProps("RestaurantTags").value}
                              maxLength={20}
                              initialValues={initialValues}
                              values={values}
                              formik={formik}
                            />
                          </Form.Group>
                        </Col> */}
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Menus">
                            <Form.Label>Menus</Form.Label>

                            <RestaurentTags
                              showCropMediaModal={showCropMediaModal}
                              setFieldValue={setFieldValue}
                              name={getFieldProps("Menus").name}
                              value={getFieldProps("Menus").value}
                              maxLength={20}
                              initialValues={initialValues}
                              values={values}
                              formik={formik}
                              restMenu={restMenu}
                              setrestMenu={setrestMenu}
                            />
                          </Form.Group>
                        </Col>
                        {extraFields.includes("highlightsTitle") && (
                          <Col lg={12}>
                            <Form.Group className="mb-3" controlId="slugName">
                              <Form.Label>
                                Highlights Title{" "}
                                <span className="mandatory">*</span>
                              </Form.Label>
                              <div className="bs-example">
                                <input
                                  ref={highlightsTitleRef}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  maxLength={100}
                                  name="highlightsTitle"
                                  value={values?.highlightsTitle}
                                  id="slugName"
                                  className="form-control py-3 shadow-none px-4"
                                  // onKeyPress={handleKeyPress}
                                  data-role="tagsinput"
                                />
                              </div>

                              {errors.highlightsTitle &&
                              touched.highlightsTitle ? (
                                <p className="form-error text-danger">
                                  {errors.highlightsTitle}
                                </p>
                              ) : error.highlightsTitle ? (
                                <p className="form-error text-danger">
                                  {error.highlightsTitle}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        )}
                        {extraFields.includes("highlightsDescription") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="highlightsDescription"
                            >
                              <Form.Label>
                                Highlights Description{" "}
                                <span className="mandatory">*</span>{" "}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                style={{ height: "100px" }}
                                onChange={handleChange}
                                ref={highlightsDescriptionRef}
                                value={values?.highlightsDescription}
                                name="highlightsDescription"
                                onBlur={handleBlur}
                                maxLength={290}
                              />
                              {errors.highlightsDescription &&
                              touched.highlightsDescription ? (
                                <p className="form-error text-danger">
                                  {errors.highlightsDescription}
                                </p>
                              ) : error.highlightsDescription ? (
                                <p className="form-error text-danger">
                                  {error.highlightsDescription}
                                </p>
                              ) : !errors.highlightsDescription &&
                                error.highlightsDescription &&
                                touched.highlightsDescription ? (
                                <p className="form-error text-danger">
                                  Please select a restaurant description
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        )}
                        {extraFields.includes("chefSignatue") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="Chief Signature"
                            >
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Chef Signature{" "}
                                  <span className="mandatory">*</span>{" "}
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={() =>
                                    handleToggleOfferImgModal(
                                      "chefSignatureImageTag"
                                    )
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!chefSignatue && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "chefSignature")
                                    }
                                    // ref={ImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {chefSignatue && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={URL.createObjectURL(chefSignatue)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(chefSignatue)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, "chefSignature")
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}
                        {extraFields.includes("leftImage") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Left Image{" "}
                                  <span className="mandatory">*</span>{" "}
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={() =>
                                    handleToggleOfferImgModal("leftImageTag")
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!leftImage && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "leftImage")
                                    }
                                    ref={ImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {leftImage && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={URL.createObjectURL(leftImage)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(leftImage)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, "leftImage")
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}
                        {extraFields.includes("rightImage") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Right Image{" "}
                                  <span className="mandatory">*</span>{" "}
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={() =>
                                    handleToggleOfferImgModal("rightImageTag")
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!rightImage && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "rightImage")
                                    }
                                    ref={ImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {rightImage && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={URL.createObjectURL(rightImage)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(rightImage)}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, "rightImage")
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}

                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="slug Name">
                            <Form.Label>
                              Slug <span className="mandatory">*</span>
                            </Form.Label>
                            <div className="bs-example">
                              <input
                                ref={slugRef}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                maxLength={100}
                                name="slugName"
                                value={values?.slugName?.trim()}
                                id="slugName"
                                className="form-control py-3 shadow-none px-4"
                                onKeyPress={handleKeyPress}
                                data-role="tagsinput"
                              />
                            </div>
                            {errors.slugName && touched.slugName ? (
                              <p className="form-error text-danger">
                                {errors.slugName}
                              </p>
                            ) : error.slugName ? (
                              <p className="form-error text-danger">
                                {error.slugName}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Row>
                          <Form.Group className="col-md-12 mb-4">
                            <Form.Label>Keywords</Form.Label>
                            <Form.Control
                              name="keyWords"
                              value={values.keyWords}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="Keywords"
                              className="form-control py-3 shadow-none px-4"
                            />
                          </Form.Group>
                        </Row>
                      </Row>
                      <Row>
                        <div className="col-lg-6">
                          <Form.Group className="mb-4">
                            <Form.Label>Meta Title</Form.Label>
                            <Form.Control
                              name="metaTitle"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaTitle"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-4">
                            <Form.Label>Meta Description</Form.Label>
                            <Form.Control
                              name="metaDescription"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaDescription"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={170}
                            />
                          </Form.Group>
                        </div>
                      </Row>
                    </Form>

                    <RestaurantDetail
                      handleThirdRemoveImage={handleThirdRemoveImage}
                      handleRemoveImage={handleRemoveImage}
                      showCropMediaModal={showCropMediaModal}
                      image={image}
                      image3={image3}
                      maincroppedThirdImage={maincroppedThirdImage}
                      // dataArray={dataArray}
                      setDataArray={setDataArray}
                      values={values}
                      handleChange={handleChange}
                      setValues={setValues}
                      getFieldProps={getFieldProps}
                      setFieldValue={setFieldValue}
                      // setDetailArray={setDetailArray}
                      setRestaurandDetailDataArray={
                        setRestaurandDetailDataArray
                      }
                      maincroppedThirdFile={maincroppedThirdFile}
                      setmainCroppedThirdFile={setmainCroppedThirdFile}
                      initialValues={initialValues}
                      restaurandDetailDataArray={restaurandDetailDataArray}
                      formik={formik}
                      // handleToggleDetailTagModal={handleToggleDetailTagModal}
                      // handleCloseDetailImgTagModal={handleCloseDetailImgTagModal}
                      // showDetailImgTagModal={showDetailImgTagModal}
                      // detailAltTagId={detailAltTagId}
                      // setDetailAltTagId={setDetailAltTagId}
                    />

                    <div className="save-btn text-end mt-4">
                      {bwebsiteId ==="TOC" && (
                        <Button
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-6 py-2 me-2"
                          onClick={() => {
                            setAction("PREVIEW");
                            checkErrorAndSubmit();
                          }}
                          disabled={isPreviewLoading}
                        >
                          {isPreviewLoading ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "PREVIEW"
                          )}
                        </Button>
                      )}
                      <Button
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => {
                          setAction("SAVE");
                          checkErrorAndSubmit();
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        ) : (
                          "SAVE"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewForDinning
              dataCommingFrom="add"
              indexForMenu={indexForMenu}
              maincroppedThirdFile={maincroppedThirdFile}
              imgArray={imgArray}
              currentFileId={currentFileId}
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedSecImage={setmainCroppedSecImage}
              setMainCroppedIcon={setMainCroppedIcon}
              setmainCroppedIconFile={setmainCroppedIconFile}
              setmainCroppedSecFile={setmainCroppedSecFile}
              setmainCroppedThirdFile={setmainCroppedThirdFile}
              setmainCroppedThirdImage={setImage3}
              setmainCroppedImage={setmainCroppedImage}
              checkValForIcon={checkValForIcon}
              maincroppedImage={maincroppedImage}
              checkValForSingleImg={checkValForSingleImg}
              setValForSingleImg={setValForSingleImg}
              checkValForSingleImg3={checkValForSingleImg3}
              checkMultipleImageSection={checkMultipleImageSection}
              setmainCroppedFile={setmainCroppedFile}
              setMainCroppedBannerImage={setMainCroppedBannerImage}
              checkValForBannerImage={checkValForBannerImage}
              setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
              restMenuValue={restMenuValue}
              setrestMenuValue={setrestMenuValue}
              setrestMenu={setrestMenu}
              restMenu={restMenu}
              restLogoValue={restLogoValue}
              setrestLogo={setrestLogo}
              setrestLogoValue={setrestLogoValue}
              //for left image
              setLeftImage={setLeftImage}
              leftImageValue={leftImageValue}
              setLeftImageValue={setLeftImageValue}
              //for right image
              setRightImage={setRightImage}
              rightImageValue={rightImageValue}
              setRightImageValue={setRightImageValue}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
              //for chef signature image
              setChefSignatue={setChefSignatue}
              chefSignatueValue={chefSignatueValue}
              setChefSignatueValue={setChefSignatueValue}
            />
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showOfferImgTagModal}
        handleCloseImageTagModal={handleCloseOfferImgTagModal}
        initialImageAltTag={values.imageAltTag}
        tagType="IMAGEALTTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />
      <ImageTagModal
        showModalImageTagModal={showOfferImgTagModal}
        handleCloseImageTagModal={() => setShowOfferImgTagModal(false)}
        initialImageAltTag={altTags[typeForImgAltTag] || ""}
        tagType={typeForImgAltTag}
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />

      <ImageTagModal
        showModalImageTagModal={showInnerBannerModal}
        handleCloseImageTagModal={handleCloseInnerBannerModal}
        initialImageAltTag={values.innerBannerImgTag}
        tagType="INNERBANNERIMGTAG"
        handleSaveImageAltTag={handleSaveInnerBannerTag}
        inputName="innerBannerImgTag"
      />

      {/* <MultipleImageAltTag
  showModalImageTagModal={showDetailImgTagModal}
  handleCloseImageTagModal={handleCloseDetailImgTagModal}
  // initialImageAltTag={initialValues.dataArray.length > 0 && initialValues.dataArray[0].detailImgAltTag ? initialValues.dataArray[detailAltTagId].detailImgAltTag : ""}  
  initialImageAltTag={values.dataArray[detailAltTagId].detailImgAltTag}  
  tagType={detailAltTagId}       
  handleSaveImageAltTag={handleSaveDetailTag}
  inputName={detailAltTagId}
/> */}
<ResortTypeModal
        show={showResortModal}
        closeModal={handleCloseResortTypeModal}
        values={values}
        editRestaurantId={editRestaurantId}
        AllRestaurantTypeId={AllRestaurantTypeId}
        onInvalidate={handleInvalidateRestaurantTypes}

      />

      <DeleteRestaurantTypeModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
        onInvalidate={handleInvalidateRestaurantTypes}
      />
    </>
  );
}

export default AddNewRestaurant;
