import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as dataquery from "../dataService/DataService";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({
  row,
  show,
  handleClose,
  rowData,
  rowNameData,
  deleteLoading,
  setDeleteLoading,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  //const id = row.original.website_offer_id;
  const handleArchive = (data) => {
    epcArchive.mutate(data);
  };
  const epcArchive = useMutation(dataquery.deletePressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
      setDeleteLoading(false);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-press-releases");
      // queryClient.invalidateQueries('getAllPressRelease')
      queryClient.resetQueries("getAllPressRelease");
      handleClose();
      setDeleteLoading(false);
    },
    onMutate: () => {
      setDeleteLoading(true);
    },
  });
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <p>
              Once you delete this data, you won't be able to recover it. Are
              you sure you want to go ahead?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary"  disabled={deleteLoading} onClick={() => handleArchive(rowData)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
