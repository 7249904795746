import React, { useEffect, useState } from "react";
import { getAllAmenities } from "../../services/service";
import {
  insertAmenity,
  getSingleAmenityData,
  updateAmenityData,
  deleteSingleAmenity,
  getAllFeatures,
  getSingleFeature,
  deleteFeature,
  updateFeature,
  addFeature,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Modal from "react-bootstrap/Modal";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AmenityModal from "./AmenityModal";
export const amenitySchema = Yup.object({
  amenityName: Yup.string().trim().required("Please enter amenity name"),
});
function AmenitiesTab1({
  setFieldValue,
  setAmenityCropImage,
  showCropMediaModal,
  amenityFileData,
  setAmenityFile,
  amenitiesMyData,
  field,
  amenityCropImage,
  
}) {
  const [modalTitle, setModalTitle] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();
  const [amenityId, setAmenityId] = useState(null);
  const { name, value } = field;
  // const [checkboxCount, setCheckboxCount] = useState(amenitiesMyData.length);

  const [selectedValues, setSelectedValues] = useState(
    amenitiesMyData ? amenitiesMyData : []
  );
  // console.log("selected values   : ",selectedValues)

  useEffect(() => {
    // Update the Formik field value whenever  change
    const filterSelectedValues = selectedValues?.filter(
      (item) => item !== 0 && item !== ""
    );
    setFieldValue(name, filterSelectedValues);
  }, [selectedValues, setFieldValue, name]);

  let AllAmenities = useQuery("getAllAmenities", getAllFeatures);

  const handleCheckboxChange = (amenityId) => {
    setSelectedValues((prevValues) => {
      // Check if the amenityId is already in the array

      if (prevValues.includes(amenityId)) {
        // Remove the amenityId if it's present
        return prevValues.filter((id) => id !== amenityId);
      } else {
        // Add the amenityId if it's not present
        return [...prevValues, amenityId];
      }
    });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
    setAmenityFile("");
  };
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleShowAmentieModal = (amenity_id, e, val) => {
    if (e) {
      e.preventDefault();
    }

    setAmenityId(amenity_id);
    setShow(true);
    setAmenityFile("");

    if (val === "edit") {
      setModalTitle("Edit Amenity");
      setModalHeading("Edit");
    } else {
      setModalTitle("Add New Amenity");
      setModalHeading("Add amenity");
    }
  };
  const getSigleAmenity = useQuery(["getSigleAmenity", +amenityId], () =>
    getSingleFeature(amenityId)
  );

  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const handleAmenityChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckBoxArray([...checkBoxArray, value]);
    } else {
      setCheckBoxArray(checkBoxArray.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    // setFieldValue("amenitiesId", checkBoxArray);
  }, [checkBoxArray, setFieldValue]);

  const initialValues = {
    amenityName: getSigleAmenity.data?.data[0]?.feature_name || "",
    amenityFromEditIcon: getSigleAmenity?.data?.data[0]?.icon_path || "",
  };

  // const amenityIcon =
  //   amenityFileData &&
  //   amenityFileData.data &&
  //   amenityFileData.data.media_file_path;
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: amenitySchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("featureName", values.amenityName);
      if (amenityFileData) {
        formData.append("icon", amenityFileData);
      } else {
        formData.append("icon", values.amenityFromEditIcon);
      }
      if (amenityId > 0) {
        updateAmentiesMutation.mutate({ formData, amenityId });
      } else {
        addAmentiesMutation.mutate(formData);
      }
      resetForm()
      setAmenityCropImage(null)
    },
  });
  

  const updateAmentiesMutation = useMutation(updateFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);

      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues(initialValues);
      setShow(false);
      setAmenityFile("");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const addAmentiesMutation = useMutation(addFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate(`/manage-venue/edit-venue/${id}`);
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues(initialValues);
      setShow(false);
      setAmenityFile("");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    if (getSigleAmenity.isSuccess) {
      setValues((prevValues) => ({
        ...prevValues,
        amenityName: getSigleAmenity.data?.data[0]?.feature_name || "",
        amenityFromEditIcon: getSigleAmenity?.data?.data[0]?.icon_path || "",
      }));
    }
  }, [getSigleAmenity.data, setValues]);


  const handleAmenityDelete = (amenityId, e) => {
    if (e) {
      e.preventDefault();
    }
    amenityDelete.mutate(amenityId);
  };
  const amenityDelete = useMutation(deleteFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllAmenities");
    },
  });

  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Features</h5>
          </div>
          <div className="header-action">
            <a
              onClick={(e) => handleShowAmentieModal(e)}
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
            >
              Add New
            </a>
          </div>
        </div>
        <div className="row mt-4">
          {AllAmenities?.data &&
            AllAmenities.data.data.map((amenity, index) => (
              <div className="col-md-3" key={amenity.venue_feature_id}>
                <div className="amenity-wrap d-flex align-items-center mb-3 border px-3 py-3">
                  <div className="check-amenity">
                    <input
                      type="checkbox"
                      value={amenity.venue_feature_id}
                      onChange={() => handleCheckboxChange(amenity.venue_feature_id)}
                      checked={selectedValues?.includes(amenity.venue_feature_id)}
                      id={amenity.venue_feature_id}
                      name={`amenities${index}`}
                    />
                  </div>
                  <div className="amenity-view d-flex ps-2">
                    <div className="icon-amenity">
                      <img
                        src={amenity.icon_path}
                        // alt="Cnopied"
                        // title="Cnopied"
                      />
                    </div>
                    <div className="name-amenity ps-2">
                      {amenity.feature_name}
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleShowAmentieModal(amenity.venue_feature_id, e, "edit")
                    }
                    className="btn btn-primary btn-sm btn-theme"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button
                    variant="info"
                    onClick={(e) => handleAmenityDelete(amenity.venue_feature_id, e)}
                    className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            ))}

          {errors.amenitiesId && touched.amenitiesId ? (
            <p className="form-error text-danger">{errors.amenitiesId}</p>
          ) : error.amenitiesId ? (
            <p className="form-error text-danger">{error.amenitiesId}</p>
          ) : null}
        </div>
        {/* <!-- /col-2 --> */}
      </div>

      {/* <Modal size="m" centered show={show} onHide={() => setShow(false)}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Edit amenity"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {/* <div className="modal-header px-5 py-4">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Add new amenity</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div> 
          <div className="position-relative px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
            <div className="upload-file-wrapper">
              <form action="#" method="get">
                <div className="facility-view">
                  <label htmlFor="FacilityOne" className="form-label ps-5">
                    Add New
                  </label>

                  <div className="input-group mb-3">
                    {amenityFileData?.data?.media_file_path ? (
                      <div
                        className="amenity-upload-img ml-4"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={
                            amenityFileData &&
                            amenityFileData.data &&
                            amenityFileData.data.media_file_path
                          }
                        ></img>
                      </div>
                    ) : (
                      <div className="amenity-upload-img ml-4">
                        <img
                          src={values.amenityFromEditIcon}
                          className=""
                          style={{ overflow: "hidden" }}
                        />
                      </div>
                    )}
                    <label
                      className="input-group-text px-3 facility-icon position-relative"
                      htmlFor="UploadFacilityIcon"
                    >
                      <i className="fa-solid fa-camera"></i>
                     
                      <input
                        type="button"
                        onClick={() => showCropMediaModal("amenityIcon")}
                        name="amenityIcon"
                        className=" btn position-absolute"
                        id="UploadFacilityIcon"
                      />
                    </label>
                    <input
                      className="form-control py-3 shadow-none px-4"
                      name="amenityName"
                      maxLength={45}
                      value={values.amenityName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.amenityName && touched.amenityName ? (
                      <p className="form-error text-danger">
                        {errors.amenityName}
                      </p>
                    ) : error.amenityName ? (
                      <p className="form-error text-danger">
                        {error.amenityName}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="pt-2 text-end mb-2">
                  <button
                    onClick={(e) => handleClose(e)}
                    className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-1"
                  >
                    Cancel
                  </button>

                  <input
                    type="submit"
                    onClick={handleSubmit}
                    value="Next"
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <AmenityModal
        show={show}
        handleClose={() => {
          setShow(false);
          setAmenityFile("");
        }}
        handleSubmit={handleSubmit}
        values={values}
        amenityFileData={amenityFileData}
        amenityCropImage={amenityCropImage}
        showCropMediaModal={showCropMediaModal}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        modalTitle={modalTitle}
        modalHeading={modalHeading}
      />
    </>
  );
}

export default AmenitiesTab1;
