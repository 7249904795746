import React, { useState, useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/multiple-select.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/jquery.mCustomScrollbar.css";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { getSideBarData } from "./pages/dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./layouts/Header";
import SideBar from "./layouts/SideBar";
import ManagePage from "../src/pages/managePages/ManagePages";
import ManageOffers from "../src/pages/manageOffers/ManageOffers";
import ManageHomePage from "./pages/managePages/ManageHomePage";
import ManageMainBanner from "./pages/managePages/homePages/ManageMainBanner";
import ManageExperienceAwait from "./pages/managePages/homePages/ManageExperienceAwait";
import Home from "./pages/home/Home";
import Welcome from "./pages/home/Welcome";
import ManageAlbum from "./pages/manageAlbum/ManageAlbum";
import Testcrop from "./pages/manageAlbum/Testcrop";
import Testcrops from "./pages/manageAlbum/Testcrops";
import CreateOffers from "./pages/manageOffers/CreateOffers";

import AlbumView from "./pages/manageAlbum/AlbumView";
import MediaFolderView from "./pages/manageAlbum/MediaFolderView";
import { ToastContainer } from "react-toastify";
import EditOffers from "./pages/manageOffers/EditOffers";
import ManagePressReleases from "./pages/managePressReleases/ManagePressReleases";
import CreatePressRelease from "./pages/managePressReleases/CreatePressRelease";
import EditPressRelease from "./pages/managePressReleases/EditPressRelease";
import Login from "./pages/login/Login";
import Message from "./pages/enquery/Message";
import Newsletter from "./pages/newsletter/Newsletter";
import EditMenues from "./pages/manageMenu/EditMenues1";
import ManageMenu from "./pages/manageMenu/ManageMenu";

import ManageUser from "./pages/manageUser/ManageUser";
import CreateUser from "./pages/manageUser/CreateUser";
import EditUser from "./pages/manageUser/EditUser";
import ManageUserRole from "./pages/manageUserRole/ManageUserRole";
import ManageAssignedUserRoles from "./pages/manageUser/ManageAssignedUserRoles";
import CreateUserRole from "./pages/manageUserRole/CreateUserRole";
import EditUserRole from "./pages/manageUserRole/EditUserRole";
import ManageUserRoleNavigationPermission from "./pages/manageUserRole/ManageUserRoleNavigationPermission";
import ManageNavigationPermission from "./pages/manageUserRole/ManageNavigationPermission";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";
import ManageProperties from "./pages/manageProperties/ManageProperties";
import CreateProperties from "./pages/manageProperties/CreateProperties";
import ManageDining from "./pages/manageDining/ManageDining";
import AddNewRestaurant from "./pages/manageDining/AddNewRestaurant";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import EditPage from "./pages/managePages/EditPage";
import EditProperty from "./pages/manageProperties/EditProperty";
import EditDining from "./pages/manageDining/EditDining";
import Log from "./pages/Logs/Log";

import ManageAwards from "./pages/managePressReleases/ManageAwards";
import ManageCollaterals from "./pages/managePressReleases/ManageCollaterals";


import Activitylogs from "./pages/activityLogs/Activitylogs";
import Dashboard from "./pages/dashboard/dashboard"
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import NewDashboard from "./pages/analytics/NewDashboard";
import AnalyticsDashboard from "./pages/analytics/AnalyticsDashboard";
import ManageVenue from "./pages/manageVenue/ManageVenue";
import CreateVenue from "./pages/manageVenue/CreateVenue";
import EditVenues from "./pages/manageVenue/EditVenues";

function App() {
  const [isSideBarActive, setIsSideBarActive] = useState(false);
  const navigate = useNavigate();

  // Get the current URL
  const location = useLocation();

  //console.log('Current URL:', location.pathname);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };

  // Usage example
  const myCookieValue = getCookie("userRole");


  // const sidebarData = useQuery(
  //   ["SideBarData123", myCookieValue], // Using an array as the query key with offerId
  //   () => getSideBarData(myCookieValue)
  // );


  // const user = getCookie('user');
  // console.log("user")
  // console.log(user)
  // if(user==''||user==null){
  //   navigate('/');
  // }

  //   const containsText = (item, searchText) => {
  //     if (item.navigation_url && item.navigation_url.includes(searchText)) {
  //       return true;
  //     }

  //     if (item.subItems) {
  //       // Check subItems recursively
  //       return item.subItems.filter((subItem) => containsText(subItem.navigation_url, searchText));
  //     }

  //     return false;
  //   };

  //   // Example: Check if any navigation_url contains the text "/manage-roles"
  //  // const searchText = "/manage-roles";
  //  if(sidebarData && sidebarData.data && sidebarData.data.data){
  //   const isTextPresent = sidebarData && sidebarData.data && sidebarData.data.data && sidebarData.data.data.filter((item) => containsText(item.navigation_url, location.pathname));

  //   if(!isTextPresent){
  //     navigate("/home");
  //   }
  // }

  // //console.log("sidebarData123")
  // //console.log(sidebarData)

  return (
    <>
      <Routes>
        <Route
          path="/websites-list"
          element={
            <>
              {" "}
              <Home />
            </>
          }
        />
        <Route
          path="/manage-pages"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManagePage />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-pages/manage-home-page/:pageId"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageHomePage />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-pages/edit-page/:pageId/:pageSectionId"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditPage />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-pages/manage-home-page/manage-main-banner"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageMainBanner />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-pages/manage-home-page/manage-experience-await"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageExperienceAwait />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-folder/manage-media/:websiteId/"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageAlbum />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-folder"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <MediaFolderView />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-folder/manage-media/album-view/:websiteId/:albumId"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <AlbumView />
                </div>
              </div>
            </>
          }
        />


        <Route
          path="/manage-offers"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageOffers />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-offers/create-offers"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreateOffers />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-offers/edit-offer/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditOffers />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/test-crop"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Testcrop />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/test-crops"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Testcrops />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-press-releases"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManagePressReleases />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-press-releases/create-press-release"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreatePressRelease />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-press-releases/edit-press-release/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditPressRelease />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-users"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageUser />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-users/create-user"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreateUser />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-users/edit-user/:userId"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />{" "}
                  <EditUser />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-user-role"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageAssignedUserRoles />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/messages"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Message />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/newsletter"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Newsletter />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-menu/manage-edit-menues"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditMenues />
                </div>
              </div>
            </>
          }
        />

        {/* <DndProvider backend={HTML5Backend}><EditMenues /></DndProvider> */}

        <Route
          path="/manage-menu"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageMenu />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-roles"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageUserRole />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-roles/create-user-role"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreateUserRole />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-roles/edit-user-role/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditUserRole />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-roles/manage-user-role-navigation"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageUserRoleNavigationPermission />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-roles/manage-navigation-permission/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageNavigationPermission />
                </div>
              </div>
            </>
          }
        />

        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:token"
          element={
            <>
              <ResetPassword />
            </>
          }
        />

        <Route
          path="/home/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Welcome />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-properties"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageProperties />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-properties/create-properties"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreateProperties />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-properties/edit-property/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditProperty />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-dining"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageDining />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-dining/add-restaurant"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <AddNewRestaurant />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/awards"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageAwards />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/collaterals"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageCollaterals />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-venue"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <ManageVenue />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-venue/create-venue"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <CreateVenue />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/manage-venue/edit-venue/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditVenues />
                </div>
              </div>
            </>
          }
        />
        <Route
          path="/activity-log"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Activitylogs />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/manage-dining/edit-restaurant/:id"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <EditDining />
                </div>
              </div>
            </>
          }
        />
        <Route path="/logs" element={<Log />}></Route>
        {/* <Route
          path="/analytics"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <Dashboard/>
                </div>
              </div>
            </>
          }
        /> */}
        <Route
          path="/analytics"
          element={
            <>
              <div
                id="pageWrapper"
                className="page-wrapper null compact-sidebar compact-small material-icon"
              >
                <div className="page-body-wrapper">
                  <Header
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <SideBar
                    isSideBarActive={isSideBarActive}
                    setIsSideBarActive={setIsSideBarActive}
                  />
                  <AnalyticsDashboard />
                </div>
              </div>
            </>
          }
        />
      </Routes>
      {/* </div>
        </div> */}
      {/* <Routes>
       
        </Routes> */}
      <ToastContainer />

      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
