import React, { useCallback, useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import { useDrag, useDrop } from "react-dnd";
import Accordion from "react-bootstrap/Accordion";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllMenus,
  insertMenu,
  getSingleMenusss,
} from "../dataService/DataService";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteModal from "./DeleteModal";
import * as querydata from "../dataService/DataService";
import { useQueries } from "react-query";
import { getSlug } from "../dataService/DataService";
//import * as querydata from "../../services/service";

const style = {
  cursor: "move",
};

export const menuSchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  slug: Yup.string().required("Please enter slug"),
});

function EditMenues() {
  const [showModal, setShowModal] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});

  const [parentId, setParentId] = useState({});
  const [isEdit, setEdit] = useState({});
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    name: "",
    slug: "",
    parentId: "",
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: menuSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("menuTitle", values.name);
      formData.append("slug", values.slug);
      formData.append("parentId", "");

      addnewprojectMutation.mutate(values);
    },
  });
  //form post
  const addnewprojectMutation = useMutation(insertMenu, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleShowModal = (menuId, name) => {
    setSelectedRowData(menuId);
    setSelectedRowNameData(name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [showAddMenuModal, setShowAddMenuModal] = useState(false);
  const [titleHeading, setTitleHeading] = useState(null);

  const handleAddMenuModal = (parentId, val) => {
    setEdit(false);
    if (val === "addMenu") {
      const addMenuHeading = "Add Menu";
      setTitleHeading(addMenuHeading);
    } else {
      const addMenuHeading = "Add sub menu";
      setTitleHeading(addMenuHeading);
    }

    setParentId(parentId);
    setShowAddMenuModal(true);
  };

  const [openAccordion, setOpenAccordion] = useState(null);
  const handleEditMenuModal = (parentId, val) => {
    setEdit(true);
    const myData =
      accordionData?.find((a) => a.menuId === parentId)?.title || "";
    if (val === "editMenu") {
      const addMenuHeading = `Edit ${myData}`;
      setTitleHeading(addMenuHeading);
    } else {
      const addMenuHeading = "Edit Sub Menu";
      setTitleHeading(addMenuHeading);
    }
    setParentId(parentId);
    setShowAddMenuModal(true);
  };
  const closeAddMenuModal = () => {
    setParentId("");
    setEdit(false);

    setShowAddMenuModal(false);
  };

  const getAllMenuslist = useQuery("getAllMenues1", getAllMenus);

  const [accordionData, setaccordionData] = useState();

  useEffect(() => {
    setaccordionData(
      getAllMenuslist && getAllMenuslist.data && getAllMenuslist.data.data
    );
  }, [getAllMenuslist.data]);

  //rohit code
  const changeSortingIndexMutation = useMutation(querydata.updatemenuIndex, {
    // Configuration options for the mutation
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      // Handle success
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const moveCard = useCallback((dragIndex, hoverIndex, item) => {
    changeSortingIndexMutation.mutate({
      menuId: item.menuId,
      newSortingIndex: hoverIndex,
    });

    setaccordionData((prevData) => {
      const findAndMove = (data, parentId) => {
        for (let i = 0; i < data.length; i++) {
          const currentItem = data[i];
          if (currentItem.menuId === parentId) {
            // Move the child element within the parent's child array
            const draggedChild = currentItem.child[dragIndex];
            currentItem.child.splice(dragIndex, 1);
            currentItem.child.splice(hoverIndex, 0, draggedChild);
            return true;
          }

          if (currentItem.child && findAndMove(currentItem.child, parentId)) {
            return true;
          }
        }

        return false;
      };

      const findAndMoveparent = (data, menuId) => {
        for (let i = 0; i < data.length; i++) {
          const currentItem = data[i];

          if (currentItem.menuId === menuId) {
            // Move the child element within the parent's child array
            //const draggedChild = currentItem.child[dragIndex];
            data.splice(dragIndex, 1);
            data.splice(hoverIndex, 0, currentItem);
            return true;
          }
        }

        return false;
      };

      const newData = [...prevData];

      if (item.parentId > 0) {
        findAndMove(newData, item.parentId);
      } else {
        // Move the parent element within the top-level array
        // newData.splice(dragIndex, 1);
        // newData.splice(hoverIndex, 0, item);

        findAndMoveparent(newData, item.menuId);
      }

      return newData;
    });
  }, []);

  if (!getAllMenuslist.data) {
    <div>Loading</div>;
  }
  if (getAllMenuslist.isLoading) {
    <div>Loading</div>;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            {/* <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="text-decoration-none" href="#">menu</a></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Menu</li>
              </ol>
            </nav> */}
            <h4 className="fw-normal m-0">Navigation</h4>
          </div>

          <div className="row mt-5">
            <div className="card p-0">
              <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                <h5 className="m-0 fw-medium">Manage Navigation</h5>
                <div className="right-actions">
                  <a
                    onClick={() => handleAddMenuModal(0, "addMenu")}
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                  >
                    Add New
                  </a>
                  {/* <a href="edit-manage-property.html" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-1">
                    Save
                  </a> */}
                </div>
              </div>

              <div className="card-body">
                <div className="menu-submenu-flow">
                  <div className="accordion" id="menuMailLevel">
                    {accordionData ? (
                      <DraggableAccordions
                        data={accordionData}
                        moveCard={moveCard}
                        handleEditMenuModal={handleEditMenuModal}
                        handleAddMenuModal={handleAddMenuModal}
                        setParentId={setParentId}
                        handleShowModal={handleShowModal}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* START ADD MODAL */}
      {showAddMenuModal && (
        <Model
          showAddMenuModal={showAddMenuModal}
          closeAddMenuModal={closeAddMenuModal}
          parentId={parentId}
          setShowAddMenuModal={setShowAddMenuModal}
          menuId={parentId}
          isEdit={isEdit}
          titleHeading={titleHeading}
        />
      )}

      <DeleteModal
        show={showModal}
        handleClose={handleCloseModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
      />
    </>
  );
}

const DraggableAccordionItem = ({
  menuId,
  title,
  content,
  child,
  index,
  moveItem,
  moveCard,
  parentId,
  handleAddMenuModal,
  setParentId,
  handleEditMenuModal,
  handleShowModal,
}) => {
  const initialValues = {
    newSortingIndex: "",
    menuId: "",
  };
  const [isLoading, setIsLoading] = useState(false);

  const [forbidDrag, setForbidDrag] = useState(false);
  //const websiteIds = getCookie('websiteId') ? getCookie('websiteId') : 0;
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      addnewprojectMutation.mutate(values);
      //action.resetForm();
    },
  });
  //form post
  const addnewprojectMutation = useMutation(querydata.updatemenuIndex, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      //navigate("/manage-offers");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "accordion-item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      setFieldValue("menuId", item.menuId);
      setFieldValue("newSortingIndex", index);

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      item.index = hoverIndex;
      moveCard(dragIndex, hoverIndex, item);
    },
    drop: (item) => {
      if (item.index === index && item.parentId === parentId) {
        return;
      }
      // handleSubmit();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "accordion-item",
    item: () => {
      return { menuId, index, parentId };
    },
    canDrag: !forbidDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <>
      <Accordion style={{ ...style, opacity }} data-handler-id={handlerId}>
        <Accordion.Item
          className="position-relative"
          ref={ref}
          eventKey={menuId}
          data={menuId}
        >
          <div className="menu-action-btns me-3">
            <a
              onClick={() => handleEditMenuModal(menuId, "editMenu")}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
            <a
              variant="info"
              onClick={() => handleShowModal(menuId, title)}
              //onClick={() => handleDeleteOffer(row.original.website_offer_id)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
              {/* <DeleteModal row={row} handleClose={handleClose} handleShow={handleShow} show={show}/> */}
            </a>
          </div>
          <Accordion.Header>
            <div className="d-flex justify-content-between w-100 align-items-center">
              {title}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {child && (
              <>
                {child.map((subItem, subIndex) => (
                  <DraggableAccordionItem
                    key={subItem.menuId}
                    {...subItem}
                    index={subIndex}
                    menuId={subItem.menuId}
                    moveItem={moveItem}
                    moveCard={moveCard}
                    parentId={subItem.parentId}
                    handleAddMenuModal={handleAddMenuModal}
                    setParentId={setParentId}
                    handleEditMenuModal={handleEditMenuModal}
                    handleShowModal={handleShowModal}
                  />
                ))}
              </>
            )}
            <div className="subnav-add mb-3 mt-3">
              <a
                key={menuId}
                onClick={() => handleAddMenuModal(menuId)}
                //onClick={handleAddMenuModal1(parentId)}
                //data-bs-toggle="modal"
                // data-bs-target="#AddNewMenuModal"
                className="btn btn-theme text-white btn-sm"
              >
                <i className="fa fa-plus" aria-hidden="true"></i> Add New
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const Model = ({
  showAddMenuModal,
  closeAddMenuModal,
  parentId,
  setShowAddMenuModal,
  menuId,
  isEdit,
  titleHeading,
}) => {
  const queryClient = useQueryClient();

  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getsinglemenu = useQuery(
    ["SingleMenu", menuId], // Using an array as the query key with offerId
    () => getSingleMenusss(menuId)
  );

  const initialValues = {
    name:
      isEdit && getsinglemenu && getsinglemenu.data && getsinglemenu.data.data
        ? getsinglemenu.data.data[0].menu_text
        : "",
    slug:
      isEdit && getsinglemenu && getsinglemenu.data && getsinglemenu.data.data
        ? getsinglemenu.data.data[0].page_id
        : "",
    page_id:
      isEdit && getsinglemenu && getsinglemenu.data && getsinglemenu.data.data
        ? getsinglemenu.data.data[0].page_id
        : "",
    parentId: parentId,
    menuId: menuId,

    isEdit: isEdit,
  };

  useEffect(() => {
    setValues({ ...initialValues });
  }, [getsinglemenu?.data]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: menuSchema,
    onSubmit: (values, action) => {
      addnewprojectMutation.mutate(values);
    },
  });
  //form post
  const addnewprojectMutation = useMutation(insertMenu, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllMenues1");
      queryClient.invalidateQueries("SingleMenu");
      setShowAddMenuModal(false);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  const handelDropdown = (e) => {
  
    setFieldValue("slug", e.target.value);
    setFieldValue("slug", e.target.value);
    setFieldValue("page_id", e.target.value);
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };

  const websiteIdFromCookie = getCookie("websiteId");

  const raw = useQuery(["getSlug", +websiteIdFromCookie], () =>
    getSlug(websiteIdFromCookie)
  );
  
  return (
    <>
      <Modal show={showAddMenuModal} onHide={closeAddMenuModal}>
        <Modal.Header closeButton>
          <Modal.Title>{titleHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
            <form action="#" method="get">
              <div className="form-group mb-4">
                <label htmlFor="Title" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={150}
                  className="form-control py-3 shadow-none px-4"
                />
                {errors.name && touched.name ? (
                  <p className="form-error text-danger">{errors.name}</p>
                ) : error.name ? (
                  <p className="form-error text-danger">{error.name}</p>
                ) : null}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="Slug" className="form-label">
                  Link to page<sup>*</sup>
                </label>

                {isEdit ? (
                  <select
                    name="slug"
                    className="form-select form-cotrol"
                    onChange={(e) => {
                      handelDropdown(e);
                    }}
                    // value={initialValues?.page_id}
                  >
                    {raw?.data?.data?.data?.map((item) => {
                      if (item.website_page_id === initialValues.page_id) {
                        return (
                          <option selected value={item.website_page_id}>
                            {item.page_title}{" "}
                          </option>
                        );
                      } else {
                        return (
                          <option value={item.website_page_id}>
                            {item.page_title}
                          </option>
                        );
                      }
                    })}
                    {errors.slug && touched.slug ? (
                      <p className="form-error text-danger">{errors.slug}</p>
                    ) : error.slug ? (
                      <p className="form-error text-danger">{error.slug}</p>
                    ) : null}
                  </select>
                ) : (
                  <select
                    name="slug"
                    className="form-select form-cotrol"
                    onChange={(e) => {
                      handelDropdown(e);
                    }}
                  >
                    <option value="">Choose</option>
                    {raw?.data?.data?.data.map((item) => (
                      <option value={item.website_page_id}>
                        {item.page_title}
                      </option>
                    ))}
                  </select>
                )}

                {errors.slug && touched.slug ? (
                  <p className="form-error text-danger">{errors.slug}</p>
                ) : error.slug ? (
                  <p className="form-error text-danger">{error.slug}</p>
                ) : null}
              </div>
              <div className="save-menu mt-4 text-end">
                <input
                  onClick={handleSubmit}
                  type="submit"
                  value="Save Menu"
                  disabled={addnewprojectMutation.isLoading}
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DraggableAccordions = ({
  data,
  moveCard,
  handleAddMenuModal,
  setParentId,
  handleEditMenuModal,
  handleShowModal,
}) => {
  const moveItem = (sourceMenuId, sourceIndex, targetMenuId, targetIndex) => {};

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {data.map((item, index) => (
          <DraggableAccordionItem
            key={item.menuId}
            {...item}
            index={index}
            menuId={item.menuId}
            moveItem={moveItem}
            moveCard={moveCard}
            //parentId={subItem.parentId}
            handleAddMenuModal={handleAddMenuModal}
            setParentId={setParentId}
            handleEditMenuModal={handleEditMenuModal}
            handleShowModal={handleShowModal}
          />
        ))}
      </DndProvider>
    </>
  );
};

export default EditMenues;
