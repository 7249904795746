import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoArrowBack, IoShareSocial } from "react-icons/io5";
import { TiArrowForward } from "react-icons/ti";
import { FaStar } from "react-icons/fa";
import { BsFullscreen } from "react-icons/bs";
import PerformingTable from "./PerformingTable";
import PerformingCountries from "./PerformingCountries";
import DemographicTabs from "./DemographicTabs";
import TableWithThead from "./TableWithThead";
import siteHealth from "../../assets/images/site-health.svg";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  getPageCountryAndWidgetData,
  getAllUserData,
  getSessionAndCountryData,
  getGoogleSearchData,
} from "../dataService/DataService";
import { useQuery } from "react-query";
import DonutChart from "../../components/charts/DonutChart.js";
import GeoDistributionChart from "../../components/charts/GeoChart.js";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { HiMiniArrowTrendingDown } from "react-icons/hi2";
import totalUsers from "../../assets/images/total-user.svg";
import newUsers from "../../assets/images/new-users.svg";
import totalClicks from "../../assets/images/total-clicks.svg";
import totalImpressions from "../../assets/images/total-impressions.svg";
import totalBookings from "../../assets/images/total-booking.svg";
import LineChartComponent from "../../components/charts/LineChart.js";
import WebsiteEngagementsStats from "./WebsiteEngagementsStats.js";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import CardSpinner from "./CardSpinner.js";

const NewDashboard = ({ websiteId }) => {
  // State to manage toggle

  const [isFilterClosed, setIsFilterClosed] = useState(false);
  const [rangeType, setRangeType] = useState("last7days");
  const [dummystartDate, setdummyStartDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClick = (newRangeType) => {
    setRangeType(newRangeType);
    setIsFilterClosed(false);
    setStartDate("");
    setdummyStartDate("");
    setEndDate("");
  };

  const pageCountryWidgetData = useQuery(
    [`pageCountryWidgetData-${websiteId}`, rangeType, startDate, endDate],
    () => getPageCountryAndWidgetData(rangeType, startDate, endDate),
    {
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );

  const analyticsWidgetData = pageCountryWidgetData?.data?.widgetData;
  const topPagesData = pageCountryWidgetData?.data?.topPageData;
  const topCountriesData = pageCountryWidgetData?.data?.topCountry;
  const intentBookingData = pageCountryWidgetData?.data?.eventPerUser;
  const tileData = pageCountryWidgetData?.data?.tileData;

  //newAndTotalUsersByDate
  const allUserData = useQuery(
    [`allUserData-${websiteId}`, rangeType, startDate, endDate],
    () => getAllUserData(rangeType, startDate, endDate),
    {
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );

  const newAndTotalUsersByDate = allUserData?.data?.totalUserData;
  const geographicalDistribution =
    allUserData?.data?.geographicalDistributionData;
  const trafficAcquisitionData = allUserData?.data?.getTrafficAcquisitionData;
  const userByDeviceCategoryData = allUserData?.data?.userByDeviceCategoryData;

  const googleSearchClickData = useQuery(
    [`google-search-data-${websiteId}`, rangeType, startDate, endDate],
    () => getGoogleSearchData(rangeType, startDate, endDate),
    {
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );

  const googleSearchClickandImpressionsData =
    googleSearchClickData?.data?.googleSearchClickImpressionData;

  const sessionAndCountryData = useQuery(
    [`getSessionAndCountryData-${websiteId}`, rangeType, startDate, endDate],
    () => getSessionAndCountryData(rangeType, startDate, endDate),
    {
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );

  const sessionbySearchEnginesData =
    sessionAndCountryData?.data?.sessionManualSourceData;

  const TrafficAcquisitionchartData = [
    ["Channel", "Active Users"],
    ...(trafficAcquisitionData?.channelData?.map((item) => [
      item.channel,
      item.activeUsers,
    ]) || []),
  ];

  const TrafficAcquisitionchartoptions = {
    // title: 'Active Users by Channel',
    pieHole: 0.4, // Creates the donut hole
    is3D: false, // Makes the chart 2D
    chartArea: { width: "90%", height: "75%" }, // Adjusts the size of the chart area
    legend: { position: "right", alignment: "center" }, // Positions the legend
    colors: [
      "#FF8C67",
      "#987BEC",
      "#FF77A0",
      "#69D3AD",
      "#76C5FF",
      "#FFBF5F",
      "#A5A9D0",
    ], // Array of colors for slices
    // colors: ['#FF6347', '#4682B4', '#32CD32', '#FFD700'], // Custom colors for the slices
    pieSliceText: "label", // Show labels inside slices
    pieSliceBorderColor: "white", // Border color of pie slices
  };

  const UsersByDeviceCategorychartData = [
    ["Channel", "Active Users"],
    ...(userByDeviceCategoryData?.deviceCategoryData?.map((item) => [
      item.deviceCategory,
      item.users,
    ]) || []),
  ];

  const UsersByDeviceCategorychartoptions = {
    // title: 'Active Users by Channel',
    pieHole: 0.4, // This makes it a donut chart
    is3D: false, // Makes the chart 2D
    chartArea: { width: "90%", height: "75%" }, // Adjusts the size of the chart area
    legend: { position: "bottom", alignment: "center" }, // Positions the legend at the bottom
    colors: [
      "#FF8C67",
      "#987BEC",
      "#FF77A0",
      "#69D3AD",
      "#76C5FF",
      "#FFBF5F",
      "#A5A9D0",
    ],
  };

  // Function to handle the toggle
  const handleToggle = () => {
    setIsFilterClosed(!isFilterClosed);
  };

  const tableTheadData = [
    { tableTheadName: "Search Engine" },
    { tableTheadName: "Users" },
    { tableTheadName: "Sessions" },
    { tableTheadName: "Eng. Sessions" },
    { tableTheadName: "Eng. rate" },
  ];
  const todayDate = new Date().toISOString().split("T")[0];
  // Handle end date change
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setRangeType("abc"); // Update rangeType when endDate changes
    setStartDate(dummystartDate);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setdummyStartDate(newStartDate);
    setStartDate(newStartDate);
    setEndDate(newStartDate);
    // if (endDate) {
    //   setStartDate(newStartDate);
    // }
    // Reset end date if it's before the new start date
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate(newStartDate);
    }
  };

  return (
    <>
      <div className="page-body position-relative">
      <div className="dashboard-action-strip bg-white px-4 py-3 w-100 position-relative mb-4 lg:mb-5">
          <div className="row">
            <div className="col-6">
              <div className="back-to-dashboard">
                <Link to="">
                  <IoArrowBack className="h5 mb-0" />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="header-right-actions mb-1 mb-md-0 d-flex justify-content-end">
                <span className="btn btn-dark border-0" data-tooltip-id={`share`}>
                  <IoShareSocial className="me-1" /> Share
                  
                </span>
                <ReactTooltip
                      id={`share`}
                      place="top"
                      content={"Coming Soon"}
                    />
              </div>
            </div>
          </div>
        </div>
        <div className="analystic-widgets">
          <div className="row">
            <div className="col-xl-3 mb-4">
              <h4>Web Analytics</h4>
            </div>
            <div className="col-xl-9 mb-xl-0 mb-4">
              <div className="d-flex align-items-center justify-content-end mb-lg-0 mb-md-4 flex-lg-nowrap flex-wrap">
                <div className="filter-btns-group position-relative pe-4 me-4">
                  <button
                    value="last7days"
                    className={`btn btn-outline fw-semibold ${
                      rangeType === "last7days" ? "active" : ""
                    }`}
                    onClick={() => handleClick("last7days")}
                  >
                    Last 7 days
                  </button>
                  <button
                    value="last30days"
                    className={`btn btn-outline fw-semibold ms-2 ${
                      rangeType === "last30days" ? "active" : ""
                    }`}
                    onClick={() => handleClick("last30days")}
                  >
                    Last 30 days
                  </button>
                  <button
                    value="last3Months"
                    className={`btn btn-outline fw-semibold ms-2 ${
                      rangeType === "last3Months" ? "active" : ""
                    }`}
                    onClick={() => handleClick("last3Months")}
                  >
                    Last 3 months
                  </button>
                </div>
                <div
                  className={`filter-btns-group custom-filter position-relative overflow-hidden ${
                    isFilterClosed ? "filter-toggle" : ""
                  }`}
                >
                  <button
                    className="btn btn-outline fw-semibold"
                    onClick={handleToggle}
                  >
                    Custom Filters <IoIosArrowForward />
                  </button>
                  <form
                    action="#"
                    method="get"
                    className="d-flex align-items-center"
                  >
                    <div className="custom-date-filters d-flex">
                      <div className="form-group">
                        <input
                          placeholder="From"
                          type="date"
                          className="form-control"
                          value={dummystartDate}
                          onChange={handleStartDateChange}
                          max={todayDate}
                        />
                      </div>
                      <div className="form-group ms-2">
                        <input
                          placeholder="To"
                          type="date"
                          className="form-control"
                          value={endDate}
                          min={dummystartDate} // Disable dates earlier than the start date
                        
                          max={todayDate} 
                          onChange={handleEndDateChange}
                          disabled={!dummystartDate}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* /row */}
          <div className="row">
            <AnalyticsCard
              loading={pageCountryWidgetData.isLoading}
              title="Total Users"
              iconSrc={totalUsers}
              primaryValue={analyticsWidgetData?.primary?.totalUsers}
              comparisonValue={
                analyticsWidgetData?.comparisonPercentage?.totalUsers
              }
            />
            <AnalyticsCard
              loading={pageCountryWidgetData.isLoading}
              title="New Users"
              iconSrc={newUsers}
              primaryValue={analyticsWidgetData?.primary?.newUsers}
              comparisonValue={
                analyticsWidgetData?.comparisonPercentage?.newUsers
              }
            />
            <AnalyticsCard
              loading={pageCountryWidgetData.isLoading}
              title="Total Organic Clicks"
              iconSrc={totalClicks}
              primaryValue={
                googleSearchClickandImpressionsData?.primary?.totalClicks
              }
              comparisonValue={
                googleSearchClickandImpressionsData?.comparisonPercentage
                  ?.clicks
              }
            />
            <AnalyticsCard
              loading={pageCountryWidgetData.isLoading}
              title="Total Organic Impressions"
              iconSrc={totalImpressions}
              primaryValue={
                googleSearchClickandImpressionsData?.primary?.totalImpressions
              }
              comparisonValue={
                googleSearchClickandImpressionsData?.comparisonPercentage
                  ?.impressions
              }
            />
            <AnalyticsCard
              loading={pageCountryWidgetData.isLoading}
              title="Visitors showing booking intent"
              iconSrc={totalBookings}
              primaryValue={`${intentBookingData?.currentEventCount}%`}
              comparisonValue={intentBookingData?.prevEventCount}
            />
          </div>
          {/* /row */}
          <div className="row">
            <div className="left-sidebar-cols w-100">
              <div className="row">
                <div className="col-xxl-6 mb-4 mb-lg-0">
                  <div className="card h-100 trfc-ratio w-100">
                    {allUserData?.isLoading ? (
                      <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <CardSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                          <h6 className="fw-semibold text-capitalize mb-0">
                            Traffic Overview
                          </h6>
                          <div className="full-view d-none">
                            <Link to="">
                              <BsFullscreen className="h6 mb-0" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body px-0 pb-0">
                          <div className="treffic-overview text-center">
                            {newAndTotalUsersByDate?.data ? (
                              <LineChartComponent
                                data={newAndTotalUsersByDate?.data}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* /col-lg-6 */}
                <div className="col-xxl-6 mt-xxl-0 mt-lg-4">
                  <div className="card h-100 analystic-table trfc-ratio w-100">
                    {pageCountryWidgetData?.isLoading ? (
                      <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <CardSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                          <h6 className="fw-semibold text-capitalize mb-0">
                            Top Performing Pages
                          </h6>
                          <div className="full-view d-none">
                            <Link to="">
                              <BsFullscreen className="h6 mb-0" />
                            </Link>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="card-body px-0 pb-0">
                      <div className="treffic-overview default-bg-table">
                        {topPagesData?.topPages ? (
                          <PerformingTable
                            tableRowData={topPagesData?.topPages}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col-lg-6 */}
              </div>
              {/* /row */}
              <div className="row mt-4">
                <div className="col-xxl-4 mb-4 mb-lg-0">
                  <div className="card h-100 tp-prmnc w-100">
                    {pageCountryWidgetData?.isLoading ? (
                      <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <CardSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                          <h6 className="fw-semibold text-capitalize mb-0">
                            Top Countries (Total Users)
                          </h6>
                          <div className="full-view d-none">
                            <Link to="">
                              <BsFullscreen className="h6 mb-0" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body px-0 pb-0">
                          <div className="perfoming-countries default-bg-table">
                            {topCountriesData?.topCountries ? (
                              <PerformingCountries
                                tableRowItems={topCountriesData?.topCountries}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* /col-lg-4 */}
                <div className="col-xxl-8 mt-xxl-0 mt-lg-4 mt-0">
                  <div className="card h-100 analystic-table geo-ratio w-100">
                    {allUserData?.isLoading ? (
                      <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <CardSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                          <h6 className="fw-semibold text-capitalize mb-0">
                            Geographical Distribution
                          </h6>
                          <div className="full-view d-none">
                            <button className="btn export-btn btn-info fw-semibold ms-2">
                              <TiArrowForward /> Export
                            </button>
                          </div>
                        </div>
                        <div className="card-body px-0 pb-0">
                          <div className="geographical-distribution my-4">
                            {/* <img src={geographicalDistribution} alt='' /> */}
                            {geographicalDistribution?.distribution ? (
                              <GeoDistributionChart
                                data={geographicalDistribution?.distribution}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* /col-lg-6 */}
              </div>
              {/* /row */}
            </div>
            {/* /col-lg-9 */}

            <div className="sidebar-cols w-100">
              <div className="card border-0 booking-card d-none">
                <div className="card-body px-0">
                  <div className="d-flex align-items-center justify-content-between my-1">
                    <div className="left-card-text">
                      <span>Bookings</span>
                      <span>lorem ipsum</span>
                    </div>
                    <div className="card-large-value google-reviews mt-1">
                      <h2 className="mb-0 h2">346</h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* /card */}
              <div className="card border-0 analystic-table d-none">
                <div className="card-body px-0 py-2 text-center">
                  <div className="site-health d-flex justify-content-center">
                    <img src={siteHealth} alt="" />
                  </div>
                  <span className="small-title">Site Health</span>
                </div>
              </div>
              {/* /card */}
              <div className="card border-0 pt-1 analystic-table d-none">
                <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                  <div className="d-flex align-items-center pt-4 pb-3">
                    <h6 className="fw-semibold text-capitalize mb-0">
                      {/* <span className='me-2'>
                                                <img width={24} src={googleLogo} alt='' />
                                            </span> */}
                      Website Engagements Stats
                    </h6>
                  </div>
                  <div className="full-view d-none">
                    <Link to="">
                      <BsFullscreen className="h6 mb-0" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pb-2 pt-2 text-center pb-0">
                  <div className="google-reviews gap-1 d-flex justify-content-center align-items-center">
                    <Link className="d-flex" to="">
                      <FaStar />
                    </Link>
                    <Link className="d-flex" to="">
                      <FaStar />
                    </Link>
                    <Link className="d-flex" to="">
                      <FaStar />
                    </Link>
                    <Link className="d-flex" to="">
                      <FaStar />
                    </Link>
                    <Link className="d-flex" to="">
                      <FaStar />
                    </Link>
                    <span className="ms-4 h2 mb-0 text-black">4.5</span>
                  </div>
                </div>
                <div className="google-reviews-list webiste-engagement-stats mt-3">
                  {/* <WebsiteEngagementsStats tileData={tileData}/> */}
                </div>
              </div>
              {/* /card */}
              <div className="card border-0 upgrade-service mt-4 d-none">
                <h5 className="text-white mt-1">
                  Upgrade to Unlock SEO Services
                </h5>
                <p className="text-white my-3">
                  Upgrade Now for Premium Access and Unlock Exclusive Feature!
                </p>
                <div className="upgrade-action mt-3 mb-3">
                  <Link
                    to=""
                    className="btn btn-secondary rounded-5 bg-white border-0 text-black"
                  >
                    Upgrade Now
                  </Link>
                </div>
              </div>
              {/* /card */}
              <div className="card engagement-card border-0 pt-1 analystic-table px-0">
                <div className="card-header bg-transparent engagement-stat-card-header border-0 d-flex align-items-center justify-content-between pt-0">
                  <div className="d-flex align-items-center pt-3 pb-3">
                    <h6 className="fw-semibold text-capitalize mb-0">
                      Website Engagements Stats
                    </h6>
                  </div>
                  <div className="full-view d-none">
                    <Link to="">
                      <BsFullscreen className="h6 mb-0" />
                    </Link>
                  </div>
                </div>
                <div className="google-reviews-list webiste-engagement-stats vscroll mt-3">
                  <SimpleBar className="h-100" autoHide={false}>
                    <WebsiteEngagementsStats
                      tileData={tileData}
                      isLoading={pageCountryWidgetData.isLoading}
                    />
                  </SimpleBar>
                </div>
              </div>
              {/* /card */}

              <div className="card engagement-card note-card border-0 pt-1 mt-4 analystic-table px-0">
                <div className="card-header bg-transparent border-0 d-flex align-items-center justify-content-between py-2">
                  <div className="d-flex align-items-center pt-3 pb-3">
                    <h6 className="fw-normal text-white mb-0">
                      <span className="fw-semibold">Please note-</span> This is
                      a Beta version of the dashboard, so feel free to share
                      your feedback with us. We hope to consistently improve it
                      with your collaboration.
                    </h6>
                  </div>
                </div>
              </div>
              {/* /card */}

              <div className="card border-0 enquiry-card mt-4 d-none">
                <div className="card-body px-0">
                  <div className="d-flex align-items-center justify-content-between my-1">
                    <div className="left-card-text">
                      <span>No. of</span>
                      <span>Enquiries</span>
                    </div>
                    <div className="card-large-value google-reviews mt-1">
                      <h2 className="mb-0 h2">25</h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* /card */}
            </div>
          </div>
          {/* /row */}
          <div className="row mt-4">
            <div className="col-xl-8 mb-4 mb-xl-0">
              <div className="card h-100 dmo-graphics-ratio w-100">
                <DemographicTabs
                  websiteId={websiteId}
                  rangeType={rangeType}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
            {/* /col-lg-8 */}
            <div className="col-xl-4">
              <div className="card h-100 sessn-ratio w-100">
                {allUserData?.isLoading ? (
                  <div className="d-flex align-items-center justify-content-center w-100 h-100">
                    <CardSpinner />
                  </div>
                ) : (
                  <>
                    <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                      <h6 className="fw-semibold text-capitalize mb-0">
                        Traffic Channels
                      </h6>
                      <div className="full-view d-none">
                        <Link to="">
                          <BsFullscreen className="h6 mb-0" />
                        </Link>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-0">
                      <div className="perfoming-countries h-100 d-flex align-items-center justify-content-center">
                        {/* <img src={imgSession} alt='' /> */}
                        {TrafficAcquisitionchartData ? (
                          <DonutChart
                            chartData={TrafficAcquisitionchartData}
                            options={TrafficAcquisitionchartoptions}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* /col-lg-4 */}
          </div>
          {/* /row */}
          <div className="row mt-4">
            <div className="col-xl-8 mb-4 mb-xl-0">
              <div className="card h-100 sesson-reasrch-ratio w-100">
                {sessionAndCountryData?.isLoading ? (
                  <div className="d-flex align-items-center justify-content-center w-100 h-100">
                    <CardSpinner />
                  </div>
                ) : (
                  <>
                    <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                      <h6 className="fw-semibold text-capitalize mb-0">
                        Breakup by Search Engine
                      </h6>
                      <div className="full-view d-none">
                        <Link to="">
                          <BsFullscreen className="h6 mb-0" />
                        </Link>
                      </div>
                    </div>
                    <div className="card-body pt-4 px-0 pb-0">
                      <div className="session-search-table default-bg-table">
                        {sessionbySearchEnginesData ? (
                          <TableWithThead
                            tableTheadData={tableTheadData}
                            tableRowDataItem={sessionbySearchEnginesData}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* /col-lg-8 */}
            <div className="col-xl-4">
              <div className="card h-100 sesson-nw-ratio w-100">
                {allUserData?.isLoading ? (
                  <div className="d-flex align-items-center justify-content-center w-100 h-100">
                    <CardSpinner />
                  </div>
                ) : (
                  <>
                    <div className="card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0">
                      <h6 className="fw-semibold text-capitalize mb-0">
                        Devices
                      </h6>
                      <div className="full-view d-none">
                        <Link to="">
                          <BsFullscreen className="h6 mb-0" />
                        </Link>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-0 py-lg-4 my-lg-2">
                      <div className="perfoming-countries h-100 d-flex align-items-center justify-content-center">
                        {/* <img src={imgDeviceTreffic} alt='' /> */}
                        {UsersByDeviceCategorychartData ? (
                          <DonutChart
                            chartData={UsersByDeviceCategorychartData}
                            options={UsersByDeviceCategorychartoptions}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* /col-lg-4 */}
          </div>
          {/* /row */}
        </div>
      </div>
    </>
  );
};

const AnalyticsCard = ({
  title = "",
  iconSrc = "",
  primaryValue = "",
  comparisonValue = "",
  loading = false, // Add loading prop
}) => {
  const hasComparisonValue =
    comparisonValue !== undefined && comparisonValue !== null;
  const comparisonIcon =
    hasComparisonValue && comparisonValue >= 0 ? (
      <HiMiniArrowTrendingUp className="ms-1 increase-icon" />
    ) : hasComparisonValue ? (
      <HiMiniArrowTrendingDown className="ms-1 decrease-icon" />
    ) : null;

  return (
    <div className="col-xxl col-md-6 mb-4">
      <div className="card border-0 widget-card">
        {loading ? (
          // Loader or skeleton when loading is true
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <CardSpinner />
          </div>
        ) : (
          // Card content when not loading
          <>
            <div className="card-top-head mb-3 d-flex align-items-center justify-content-between">
              <span className="anylystic-user d-flex pe-lg-2">{title}</span>
              <span
                className={`value-stats text-nowrap ${
                  hasComparisonValue && comparisonValue >= 0
                    ? "increase-value"
                    : "decrease-value"
                }`}
              >
                {hasComparisonValue
                  ? `${comparisonValue >= 0 ? "+" : ""}${comparisonValue}%`
                  : "0"}
                {comparisonIcon}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="anylystic-card-icon">
                <img src={iconSrc} alt={title} />
              </div>
              <div className="anylystic-card-value ms-3">
                <h2>{primaryValue}</h2>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewDashboard;
